<template>
    <content-component>
        <template v-slot:table_create_button>
            <router-link :to="{name: 'health_center.create'}" class="btn custom-btn-02 w-100">
                <i class="fas fa-plus"></i>
                Puskesmas
            </router-link>
        </template>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ data.name }}</td>
                        <td>{{ data.regency_name }}</td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <router-link :to="{name: 'health_center.detail', params: {id: data.id}}" class="btn custom-btn-unborder-02 py-0">
                                    <i class="fas fa-eye"></i>
                                </router-link>
                                <router-link :to="{name: 'health_center.edit', params: {id: data.id}}" class="btn custom-btn-unborder-02 py-0">
                                    <i class="fas fa-pen"></i>
                                </router-link>
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="delete_data(data.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
        },
        data() {
            return {
                table_header: [
                    { name: 'name', title: 'Nama', width: '45%', sort: true },
                    { name: 'regency', title: 'Kabupaten', width: '45%', sort: false },
                    { name: '', title: 'Actions', width: '10%', sort: false, align:'text-center' },
                ],
                table_attributes: {
                    sort_by: 'name',
                    sort_type: 'asc',
                    search: null,
                    page: 1,
                    paginate: 30,
                },
                table: null,
                table_data: [],
            }
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('health_center/getHealthCenter', value).then((response) => {
                    this.table = response.data
                    if(value.page == 1){
                        this.table_data = this.table.data
                    }
                    else{
                        this.table_data = [ ...this.table_data, ...this.table.data]
                    }
                    this.table_attributes = value
                })
            },
            delete_data(value){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('health_center/deleteHealthCenter', value).then(() => {
                            parent.table_attributes.page = 1
                            parent.get_table(parent.table_attributes)
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil menghapus data',
                                confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                confirmButtonText: parent.$root.swal('doneButtonText'),
                            })
                        })
                    }
                })
            },
        }
	}
</script>