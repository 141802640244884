<template>
	<div class="row" v-infinite-scroll="load_table" :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="loading">
		<div class="col-12 col-lg-6">
			<div class="row">
				<slot name="table_button"></slot>
			</div>
		</div>
		<div class="col-12 col-lg-6">
			<div class="row justify-content-end">
				<div class="col-12 col-lg-6" v-if="attributes.hasOwnProperty('filter')">
					<div class="input-group mb-3">
						<v-select v-model="filter" :options="filter_options" :label="filter_options.label" :placeholder="'Filter'" :searchable="false" class="custom-vselect custom-vselect-append"/>
						<div class="input-group-append">
							<button type="button" class="btn custom-btn-02 px-3"><i class="fas fa-filter"></i></button>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6" v-if="attributes.hasOwnProperty('search')">
					<div class="input-group mb-3">
						<input type="text" v-model="local_attributes.search" class="form-control custom-form-control custom-form-control-append" placeholder="Search">
						<div class="input-group-append">
							<button type="button" class="btn custom-btn-02 px-3" @click="search_table()"><i class="fas fa-search"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 mt-3">
			<div ref="table" class="custom-table-wrapper">
				<table class="table table-bordered table-hover custom-table mb-0">
					<thead v-if="table_header">
						<tr>
							<th v-for="(header, index) in table_header" :key="index" :style="'width: '+header.width+';'">
								<div class="position-relative" :class="header.align">
									{{ header.title }}
									<div v-if="header.sort" class="custom-table-sort">
										<button type="button" class="btn p-0 d-block" :class="attributes.sort_by == header.name && attributes.sort_type == 'asc' ? 'custom-btn-unborder-secondary' : 'custom-btn-unborder-primary'" style="line-height: 1;" @click="sort_asc(header.name)" :disabled="attributes.sort_by == header.name && attributes.sort_type == 'asc'">
											<svg width="12" height="10" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M16 9L9 2L2 9" stroke="#58595B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</button>
										<button type="button" class="btn p-0 d-block" :class="attributes.sort_by == header.name && attributes.sort_type == 'desc' ? 'custom-btn-unborder-secondary' : 'custom-btn-unborder-primary'" style="line-height: 1;" @click="sort_desc(header.name)" :disabled="attributes.sort_by == header.name && attributes.sort_type == 'desc'">
											<svg width="12" height="10" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M2 2L9 9L16 2" stroke="#58595B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</button>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody v-if="table && !busy">
						<tr v-if="table.data.length == 0 && !loading">
							<td :colspan="table_header.length" class="text-center">No Data.</td>
						</tr>
						<template v-else>
							<slot name="table_body"/>
						</template>
					</tbody>
				</table>
				<div class="d-flex justify-content-center p-3" v-if="loading">
					<div class="custom-loading-dot"></div>
				</div>
			</div>
			<div class="custom-table-sticky-counter" v-if="table">{{ count }} of {{ table.total }} entries displayed</div>
		</div>
	</div>
</template>

<script>
	import vSelect from 'vue-select'

	export default {
		props:['attributes', 'table_header', 'table'],
		components: {
			'v-select': vSelect,
		},
		data() {
			return {
				local_attributes: {
					sort_by: null,
					sort_type: null,
					page: null,
					paginate: null,
				},
				count: 0,
				observe: null,
				loading: true,
				busy: true,
			}
		},
		computed: {
			filter: {
				get(){
					return this.local_attributes.filter
				},
				set(modifiedValue){
					this.local_attributes.filter = modifiedValue
					this.change_table()
				}
			},
		},
		watch: {
			table: {
				deep: true,
				handler: function(newData){
					if(newData.current_page == 1){
						this.count = newData.data.length
					}
					else{
						this.count = this.count + newData.data.length
					}
					this.preload_table()
					this.loading = false
					this.busy = false
				}
			},
			attributes: {
				deep: true,
				handler: function(newData){
					this.local_attributes.sort_by = newData.sort_by ? this.$root.copy(newData.sort_by) : null
					this.local_attributes.sort_type = newData.sort_type ? this.$root.copy(newData.sort_type) : null
					if(Object.prototype.hasOwnProperty.call(this.attributes, 'search')){
						this.local_attributes.search = this.$root.copy(newData.search)
					}
					if(Object.prototype.hasOwnProperty.call(this.attributes, 'filter_by')){
						this.local_attributes.filter_by = this.$root.copy(newData.filter_by)
					}
					if(Object.prototype.hasOwnProperty.call(this.attributes, 'filter')){
						this.local_attributes.filter = this.$root.copy(newData.filter)
					}
					this.local_attributes.page = newData.page ? this.$root.copy(newData.page) : null
					this.local_attributes.paginate = newData.paginate ? this.$root.copy(newData.paginate) : null
				}
			},
		},
		mounted(){
			this.local_attributes.sort_by = this.attributes.sort_by ? this.$root.copy(this.attributes.sort_by) : null
			this.local_attributes.sort_type = this.attributes.sort_type ? this.$root.copy(this.attributes.sort_type) : null
			if(Object.prototype.hasOwnProperty.call(this.attributes, 'search')){
				this.local_attributes.search = this.$root.copy(this.attributes.search)
			}
			if(Object.prototype.hasOwnProperty.call(this.attributes, 'filter_by')){
				this.local_attributes.filter_by = this.$root.copy(this.attributes.filter_by)
			}
			if(Object.prototype.hasOwnProperty.call(this.attributes, 'filter')){
				this.local_attributes.filter = this.$root.copy(this.attributes.filter)
			}
			this.local_attributes.page = this.attributes.page ? this.$root.copy(this.attributes.page) : null
			this.local_attributes.paginate = this.attributes.paginate ? this.$root.copy(this.attributes.paginate) : null
		},
		methods: {
			preload_table(){
				if(this.$refs.table.scrollHeight > 100 && this.$refs.table.scrollHeight <= window.innerHeight){
					this.load_table()
				}
			},
			load_table(){
				if(this.table){
					this.local_attributes.page++
					if(this.local_attributes.page <= this.table.last_page){
						this.loading = true
						this.$emit('get_table', this.local_attributes)
					}
					if(this.local_attributes.page > this.table.last_page){
						this.local_attributes.page--
					}
				}
			},
			search_table(){
				this.change_table()
			},
			sort_asc(value){
				this.local_attributes.sort_by = value
				this.local_attributes.sort_type = 'asc'
				this.change_table()
			},
			sort_desc(value){
				this.local_attributes.sort_by = value
				this.local_attributes.sort_type = 'desc'
				this.change_table()
			},
			change_table(){
				this.loading = true
				this.busy = true
				this.local_attributes.page = 1
				this.$emit('get_table', this.local_attributes)
			},
		}
	}
</script>