const getUser = (state) => {
	return state.user;
}

const getSignInForm = (state) => {
	return state.sign_in_form;
}

export {
    getUser,
    getSignInForm,
};