const isLoading = (state) => {
    return state.loading
}
const isRefreshToken = (state) => {
    return state.refresh_token
}

export {
    isLoading,
    isRefreshToken,
};