const getDefaultState = () => {
	return {
		health_center_form: {
			code: null,
			name: null,
			address: null,
			phone: null,
			lat: null,
			long: null,
			province_id: null,
			regency_id: null,
			district_id: null,
			information: null,
			image: null,
			logo: null,
			is_apm: null,
			is_simpus: null
		},
		health_center_options: [],
	}
}

const default_state = getDefaultState()

export { default_state }