const getBannerForm = (state) => {
    return state.banner_form
}

const getProvinceOptions = (state) => {
    return state.province_options
}

const getRegencyOptions = (state) => {
    return state.regency_options
}

const getDistrictOptions = (state) => {
    return state.district_options
}

export {
    getBannerForm,
    getProvinceOptions,
    getRegencyOptions,
    getDistrictOptions,
};