const SET_PROVINCE_OPTIONS = (state, payload) => {
    state.province_options = []
    for (let i = 0; i < payload.data.length; ++i) {
        state.province_options.push({
            id: payload.data[i].id,
            label: payload.data[i].name
        })
    }
}

const SET_REGENCY_OPTIONS = (state, payload) => {
    state.regency_options = []
    for (let i = 0; i < payload.data.length; ++i) {
        state.regency_options.push({
            id: payload.data[i].id,
            label: payload.data[i].name
        })
    }
}

const SET_DISTRICT_OPTIONS = (state, payload) => {
    state.district_options = []
    for (let i = 0; i < payload.data.length; ++i) {
        state.district_options.push({
            id: payload.data[i].id,
            label: payload.data[i].name
        })
    }
}

const RESET_REGENCY_OPTIONS = (state) => {
    state.regency_options = []
}

const RESET_DISTRICT_OPTIONS = (state) => {
    state.district_options = []
}

const RESET_BANNER_FORM = (state) => {
    state.banner_form = {
        title: null,
        image: null,
    }
}

export {
    SET_PROVINCE_OPTIONS,
    SET_REGENCY_OPTIONS,
    SET_DISTRICT_OPTIONS,
    RESET_REGENCY_OPTIONS,
    RESET_DISTRICT_OPTIONS,
    RESET_BANNER_FORM,
}