<template>
    <content-component>
        <template v-slot:content_body>
            <div class="row">
                <div class="col-12">
                    <div class="custom-text-wrapper mb-4">
                        <div class="row">
                            <div class="col-12 mb-3" :class="validation.firstError('form.category_id') ? 'custom-form-error' : ''">
                                <label class="form-label">Kategori</label>
                                <v-select v-model="form.category_id" :options="article_category_options" placeholder="Kategori" label="label" :reduce="article_category_options => article_category_options.id" class="custom-vselect"/>
                                <div class="custom-form-error-message">{{ validation.firstError('form.category_id') }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="validation.firstError('form.title') ? 'custom-form-error' : ''">
                                <label class="form-label">Judul</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Judul" v-model="form.title">
                                <div class="custom-form-error-message">{{ validation.firstError('form.title') }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="validation.firstError('form.excerpt') ? 'custom-form-error' : ''">
                                <label class="form-label">Kutipan</label>
                                <textarea for="description" class="form-control custom-form-control" rows="3" placeholder="Kutipan" v-model="form.excerpt"></textarea>
                                <div class="custom-form-error-message">{{ validation.firstError('form.excerpt') }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="validation.firstError('form.body') ? 'custom-form-error' : ''">
                                <label class="form-label">Deskripsi</label>
                                <textarea for="description" class="form-control custom-form-control" rows="7" placeholder="Deskripsi" v-model="form.body"></textarea>
                                <div class="custom-form-error-message">{{ validation.firstError('form.body') }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="validation.firstError('form.url') ? 'custom-form-error' : ''">
                                <label class="form-label">Url</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Url" v-model="form.url">
                                <div class="custom-form-error-message">{{ validation.firstError('form.url') }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="validation.firstError('form.image') ? 'custom-form-error' : ''">
                                <label>Gambar</label>
                                <file-input
                                    :reference="'image'"
                                    :multiple="true"
                                    :maximum="1"
                                    :max_size="2097152"
                                    :accept="'image/png, image/jpg, image/jpeg'"
                                    :extensions="['png', 'jpg', 'jpeg']"
                                    @set_queue="queue_files"
                                    :old_file="local_file"
                                    :old_delete="true"
                                />
                                <div class="custom-form-error-message">{{ validation.firstError('form.image') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-12 col-lg-3 order-lg-2">
                            <button type="button" class="btn custom-btn-02 w-100" @click="submit()">{{ id ? 'Simpan' : 'Tambah' }}</button>
                        </div>
                        <div class="col-12 col-lg-3 order-lg-1">
                            <router-link :to="{name: 'article'}" class="btn custom-btn-outline-02 w-100">Kembali</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-component>
</template>

<script>
    import ContentComponent from '@/components/external/ContentComponent'
    import FileInput from '@/components/external/input/FileInput'
    import vSelect from 'vue-select'
    import { Validator } from 'simple-vue3-validator'
    import { mapGetters } from 'vuex'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'file-input': FileInput,
            'v-select': vSelect,
        },
        data() {
            return {
                id: this.$route.params.id,
                local_file: [],
            }
        },
        computed: {
            ...mapGetters({
                form: 'article/getArticleForm',
                article_category_options: 'article/getArticleCategoryOptions',
            }),
        },
        validators: {
            'form.category_id': function(value){
                return Validator.value(value).required('Wajib dipilih.')
            },
            'form.title': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
            'form.excerpt': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
            'form.body': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
            'form.url': function(value){
                if(this.form.url){
                    return Validator.value(value).custom(function (){
                        /* eslint-disable-next-line */
                        if(!(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig.test(value))){
                            return 'Format alamat url salah.'
                        }
                    })
                }
            },
            'form.image': function(value){
                return Validator.custom(function () {
                    if (!Validator.isEmpty(value)) {
                        for(var i = 0; i < value.length; i++) {
                            if (value[i].error != '') {
                                return 'Invalid file format.'
                            }
                        }
                    }
                })
            },
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('article/resetArticleForm')
                this.$store.dispatch('article/getArticleCategory')

                if(this.id){
                    this.$store.dispatch('article/detailArticle', this.id).then((response) => {
                        if(response.status){
                            this.form.title = response.data.title
                            this.form.body = response.data.body
                            this.form.excerpt = response.data.excerpt
                            this.form.url = response.data.url
                            this.form.category_id = response.data.category_id

                            if(response.data.image){
                                this.local_file.push({
                                    error: '',
                                    file_status: this.$root.copy(response.data.image),
                                    file_name: this.$root.copy(response.data.image),
                                    file_url: this.$root.copy(response.data.image_url),
                                })
                            }
                        }
                    })
                }
            },
            queue_files(data){
                if(data.files.length > 0){
                    let files = []
                    for (let i = 0; i < data.files.length; ++i) {
                        if(data.files[i].file){
                            files.push({
                                error: data.files[i].error,
                                file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
                            })
                        }
                        else{
                            files.push({
                                error: data.files[i].error,
                                file_status: data.files[i].file_status,
                                file_name: data.files[i].file_name,
                                file_url: data.files[i].file_url
                            })
                        }
                    }
                    this.form[data.ref] = files
                }
                else{
                    this.form[data.ref] = null
                }
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        let formData = new FormData();
                        formData.append('title', parent.form.title)
                        formData.append('body', parent.form.body)
                        formData.append('excerpt', parent.form.excerpt)
                        formData.append('category_id', parent.form.category_id)

                        if(parent.form.url){
                            formData.append('url', parent.form.url)
                        }

                        if(parent.form.image){
                            for(let i= 0; i < parent.form.image.length; i++) {
                                if(parent.form.image[i].file){
                                    formData.append("image", parent.form.image[i].file, parent.form.image[i].file.name)
                                }
                                else{
                                    formData.append('image', parent.form.image[i].file_status)
                                }
                            }
                        }
                        if(parent.id){
                            let data = {
                                id: parent.id,
                                data: formData
                            }
                            parent.$store.dispatch('article/updateArticle', data).then((response) => {
                                if(response.status){
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil mengubah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                    parent.$router.push({name: 'article.detail', params: {id: parent.id}})
                                }
                            })
                        }
                        else{
                            parent.$store.dispatch('article/createArticle', formData).then((response) => {
                                if(response.status){
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil menambah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                    parent.$router.push({name: 'article'})
                                }
                            })
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
            },
		}
	}
</script>