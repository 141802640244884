import Axios from 'axios'
import { constants } from '@/config/constant'

const getPolyclinic = async (context, payload) => {
    const url = constants.base_url + '/polyclinic/queue'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    return response.data
}

export {
    getPolyclinic
}