<template>
	<!-- loading -->
	<div class="custom-loading" v-if="loading">
        <i class="fas fa-circle-notch fa-spin fa-5x"></i>
    </div>
	<!-- Navbar Component -->
	<navbar-component :user="user" :sidebar="sidebar" @clicked="sidebar_toggle"></navbar-component>
	<!-- Content wrapper -->
	<div :class="sidebar ? 'custom-wrapper': 'custom-wrapper toggled'">
		<!-- Sidebar Component-->
		<sidebar-component :user="user" @clicked="sidebar_toggle"></sidebar-component>
		<!-- Content component -->
		<div class="custom-page-content-wrapper">
			<div class="custom-page-content-mask d-block d-lg-none"></div>
			<div class="custom-page-content">
				<div class="container-fluid shadow-sm p-0 px-lg-2">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Navbar from './NavbarComponent'
	import Sidebar from './SidebarComponent'
	import { mapGetters } from 'vuex'

	export default {
		components: {
			'navbar-component': Navbar,
			'sidebar-component': Sidebar
		},
		data() {
			return {
				sidebar: true
			}
		},
		computed: {
            ...mapGetters({
                user: 'auth/getUser',
				loading: 'utilities/isLoading',
            }),
        },
		created(){
            this.$store.dispatch('auth/checkAuth')
        },
		methods: {
			sidebar_toggle(value) {
				this.sidebar = value
			}
		}
	}
</script>