<template>
    <div class="container-fluid custom-auth-wrapper">
        <div class="row g-0 align-items-center" style="height: 100vh;">
            <div class="col-12 col-lg-3 mx-auto">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
	export default {

	}
</script>
