const getDefaultState = () => {
	return {
		user_form: {
			role: null,
			name: null,
			email: null,
			phone: null,
			health_center_id: null,
			password: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }