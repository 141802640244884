const isLoading = async (context, payload) => {
    context.commit('SET_LOADING', payload)
}

const isRefreshToken = async (context, payload) => {
    context.commit('SET_REFRESH_TOKEN', payload)
}

export {
    isLoading,
    isRefreshToken,
}