<template>
    <GoogleMap :api-key="api_key" :center="local_center" :zoom="15" :style="'width: ' + size.width + '; height: ' + size.height">
        <Marker :options="{ position: local_center, draggable: drag ? true : false}" @drag="update_coordinates"/>
    </GoogleMap>
</template>

<script>
    import { constants } from '@/config/constant'
    import { defineComponent, toRefs } from 'vue'
    import { GoogleMap, Marker } from 'vue3-google-map'
    
    export default defineComponent({
        name: 'GoogleMapsComponent',
        props: ['center', 'size', 'drag'],
        emits: ['changed'],
        components: { GoogleMap, Marker },
        setup(props, { emit }){
            let { center } = toRefs(props)
            let { drag } = toRefs(props)
            const api_key = constants.google_console_key
            const local_center = center

            function update_coordinates(location) {
                if(drag){
                    emit('changed', {
                        lat: location.latLng.lat(),
                        lng: location.latLng.lng(),
                    });
                }
            }

            return {
                api_key,
                local_center,
                update_coordinates
            }
        },
    })
</script>