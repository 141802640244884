<template>
    <content-component>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ data.name }}</td>
                        <td>{{ data.province_name }}</td>
                    </tr>
                </template>
            </table-component>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
        },
        data() {
            return {
                table_header: [
                    { name: 'name', title: 'Kabupaten', width: '50%', sort: true },
                    { name: 'province', title: 'Provinsi', width: '50%', sort: false },
                ],
                table_attributes: {
                    sort_by: 'name',
                    sort_type: 'asc',
                    search: null,
                    page: 1,
                    paginate: 30,
                },
                table: null,
                table_data: [],
            }
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('master_data/getRegency', value).then((response) => {
                    this.table = response.data
                    if(value.page == 1){
                        this.table_data = this.table.data
                    }
                    else{
                        this.table_data = [ ...this.table_data, ...this.table.data]
                    }
                    this.table_attributes = value
                })
            },
        }
	}
</script>