const getConstants = () => {
	return {
		base_url: 'https://jq-api.ktr.my.id/api',
		crypto_key: 'AIzaSyCZZiknmQYd__uqoQxXf-9DA3oEMcj28AU',
		google_console_key: 'AIzaSyCZZiknmQYd__uqoQxXf-9DA3oEMcj28AU',
	};
};

const constants = getConstants();

export { constants };