const getDefaultState = () => {
	return {
		article_form: {
			title: null,
			body: null,
			excerpt: null,
			image: null,
			url: null,
			category_id: null,
		},
		article_category_options: [],
	}
}

const default_state = getDefaultState()

export { default_state }