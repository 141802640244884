<template>
	<!-- Sidebar -->
	<div class="custom-sidebar-wrapper" v-if="user">
		<div class="custom-sidebar-group">
			<!-- Dashboard -->
			<router-link :to="{ name: 'dashboard' }" :class="sidebar == 'dashboard' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
				<i class="nav-icon fas fa-tachometer-alt"></i>
				<p>Dashboard</p>
			</router-link>
			<!-- User -->
			<router-link :to="{ name: 'user' }" :class="sidebar == 'user' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()" v-if="['Admin'].includes(user.role_name)">
				<i class="nav-icon fas fa-user"></i>
				<p>Pengguna</p>
			</router-link>
			<!-- Health Center -->
			<router-link :to="user.role_id == 1 ? { name: 'health_center' } : {name: 'health_center.detail', params: {id: user.profile.health_center_id}}" :class="sidebar == 'health_center' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()" v-if="['Admin','Petugas'].includes(user.role_name)">
				<i class="fas fa-medkit"></i>
				<p>Puskesmas</p>
			</router-link>
			<!-- Queue History -->
			<router-link :to="{ name: 'queue_history.polyclinic' }" :class="sidebar == 'queue_history' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()" v-if="['Admin'].includes(user.role_name)">
				<i class="fas fa-history"></i>
				<p>Riwayat Antrian</p>
			</router-link>
			<!-- Article -->
			<router-link :to="{ name: 'article' }" :class="sidebar == 'article' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()" v-if="['Admin', 'Penulis'].includes(user.role_name)">
				<i class="far fa-newspaper"></i>
				<p>Artikel</p>
			</router-link>
			<!-- Master Data -->
			<button class="custom-sidebar-menu" :class="parent == 'master_data' ? '' : 'collapsed'" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMasterData" :aria-expanded="parent == 'master_data' ? true : false" v-if="['Admin'].includes(user.role_name)">
				<i class="fas fa-database"></i>
				<div class="custom-sidebar-collapse">
					<p>Data Dasar</p>
					<i class="fas fa-angle-up"></i>
				</div>
			</button>
			<div class="collapse" :class="parent == 'master_data' ? 'show' : ''"  id="collapseMasterData">
				<router-link :to="{name: 'master_data.province'}" :class="sidebar == 'master_data.province' ? 'custom-sidebar-menu custom-sidebar-submenu active': 'custom-sidebar-menu custom-sidebar-submenu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Provinsi</p>
				</router-link>
				<router-link :to="{name: 'master_data.regency'}" :class="sidebar == 'master_data.regency' ? 'custom-sidebar-menu custom-sidebar-submenu active': 'custom-sidebar-menu custom-sidebar-submenu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Kabupaten</p>
				</router-link>
				<router-link :to="{name: 'master_data.district'}" :class="sidebar == 'master_data.district' ? 'custom-sidebar-menu custom-sidebar-submenu active': 'custom-sidebar-menu custom-sidebar-submenu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Kecamatan</p>
				</router-link>
				<router-link :to="{name: 'master_data.banner'}" :class="sidebar == 'master_data.banner' ? 'custom-sidebar-menu custom-sidebar-submenu active': 'custom-sidebar-menu custom-sidebar-submenu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Banner</p>
				</router-link>
			</div>
		</div>
		<div class="custom-sidebar-footer">
			Core © {{ current_year }}.
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props:['user'],
		data() {
			return {
				health_center: null,
				current_year: moment().year(),
			}
		},
		computed: {
			parent() {
				return this.$route.meta.parent
			},
			sidebar() {
				return this.$route.meta.sidebar
			}
		},
		methods: {
			auto_close(){
				if(window.innerWidth < 992){
					this.$emit('clicked', true)
				}
			}
		}
	}
</script>