const getHealthCenterForm = (state) => {
    return state.health_center_form
}

const getHealthCenterOptions = (state) => {
    return state.health_center_options
}

export {
    getHealthCenterForm,
    getHealthCenterOptions,
};