const SET_USER = (state, payload) => {
	state.user = payload
}

const RESET_USER = (state) => {
	state.user = null
}

const RESET_SIGN_IN_FORM = (state) => {
	state.sign_in_form = {
        email: null,
        password: null
    }
}

export {
    SET_USER,
    RESET_USER,
    RESET_SIGN_IN_FORM,
}