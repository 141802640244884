<template>
    <!-- Card wrapper -->
    <div class="card custom-card shadow-sm">
        <!-- Card header slot -->
        <div class="card-header custom-card-header" v-if="has_header_slot">
            <slot name="card_header"></slot>
        </div>
        <!-- End card header slot -->
        <!-- Card body slot -->
        <div class="card-body custom-card-body" v-if="has_body_slot">
            <slot name="card_body"></slot>
        </div>
        <!-- End card body slot -->
        <!-- Card footer slot -->
        <div class="card-footer custom-card-footer" v-if="has_footer_slot">
            <slot name="card_footer"></slot>
        </div>
        <!-- End card footer slot -->
    </div>
</template>

<script>
	export default {
        computed: {
            has_header_slot() {
                return !!this.$slots.card_header
            },
            has_body_slot() {
                return !!this.$slots.card_body
            },
            has_footer_slot() {
                return !!this.$slots.card_footer
            }
        },
	}
</script>