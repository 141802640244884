const SET_HEALTH_CENTER_OPTIONS = (state, payload) => {
    state.health_center_options = []
    for (let i = 0; i < payload.data.length; ++i) {
        state.health_center_options.push({
            id: payload.data[i].id,
            label: payload.data[i].name
        })
    }
}

const RESET_HEALTH_CENTER_FORM = (state) => {
    state.health_center_form = {
        code: null,
        name: null,
        address: null,
        phone: null,
        lat: null,
        long: null,
        province_id: null,
        regency_id: null,
        district_id: null,
        information: null,
        image: null,
        logo: null,
        is_apm: null,
        is_simpus: null
    }
}

export {
    SET_HEALTH_CENTER_OPTIONS,
    RESET_HEALTH_CENTER_FORM,
}