const SET_LOADING = (state, payload) => {
    state.loading = payload
}

const SET_REFRESH_TOKEN = (state, payload) => {
    state.refresh_token = payload
}

export {
    SET_LOADING,
    SET_REFRESH_TOKEN,
}