import { createApp } from 'vue'
import router from './router'
import store from './store'
import { initialize } from './config/general'
import { copy, currency, swal } from './config/helper'
import SimpleVueValidator from 'simple-vue3-validator'
import infiniteScroll from 'vue3-infinite-scroll-good'
import VueViewer from 'v-viewer'
import CKEditor from '@ckeditor/ckeditor5-vue'
import 'bootstrap'
import '@popperjs/core'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-select/dist/vue-select.css'
import 'viewerjs/dist/viewer.css'
import 'cropperjs/dist/cropper.css'
import './assets/sass/app.scss'

//Main Application
import MainApp from './components/MainApp'

//Build
const app = createApp(MainApp)

//Libraries Configuration
initialize(router, store)

app.use(router)
app.use(store)
app.use(SimpleVueValidator, {mode: 'conservative'})
app.use(infiniteScroll)
app.use(VueViewer)
app.use(CKEditor)

app.mixin({
    methods: {
        copy: copy,
        currency: currency,
        swal: swal,
    }
})

//Layouts
app.component('auth-layout', require('./components/auth/AuthLayoutComponent.vue').default)
app.component('admin-layout', require('./components/admin/layouts/AdminLayoutComponent.vue').default)
app.component('error-layout', require('./components/error/ErrorLayoutComponent.vue').default)

// Render
app.mount('#app')