const getArticleForm = (state) => {
    return state.article_form
}

const getArticleCategoryOptions = (state) => {
    return state.article_category_options
}

export {
    getArticleForm,
    getArticleCategoryOptions,
};