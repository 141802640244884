import Axios from 'axios'
import { constants } from '@/config/constant'

const countUser = async () => {
    const url = constants.base_url + '/user/count'
    let response = await Axios.get(url)

    return response.data
}

const tableUser = async (context, payload) => {
    const url = constants.base_url + '/user/all'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    return response.data
}

const detailUser = async (context, payload) => {
    const url = constants.base_url + '/user/detail/' + payload
    let response = await Axios.get(url)

    return response.data
}

const createUser = async (context, payload) => {
    const url = constants.base_url + '/user/create'
    let response = await Axios.post(url, payload)

    return response.data
}

const updateUser = async (context, payload) => {
    const url = constants.base_url + '/user/update/' + payload.id
    let response = await Axios.patch(url, payload.data)

    return response.data
}

const resetUserForm = async (context) => {
    context.commit('RESET_USER_FORM')
}

export {
    countUser,
    tableUser,
    detailUser,
    createUser,
    updateUser,
    resetUserForm,
}