<template>
	<transition name="fade">
		<div class="custom-modal-mask" v-if="modal_status">
			<div class="custom-modal-dialog modal-dialog align-items-center" :class="modal_size">
				<div class="modal-content">
					<div class="modal-header">
						<slot name="modal_header"></slot>    
					</div>
					<div class="modal-body">
						<slot name="modal_body"></slot>  
					</div>
					<div class="modal-footer">
						<slot name="modal_footer"></slot>  
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		props:['modal_status', 'modal_size']
	}
</script>