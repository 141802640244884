const RESET_USER_FORM = (state) => {
    state.user_form = {
        role: null,
        name: null,
        email: null,
        phone: null,
        healt_center_id: null,
        password: null,
    }
}

export {
    RESET_USER_FORM
}