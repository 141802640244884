import Axios from 'axios'
import { constants } from '@/config/constant'

const countHealthCenter = async () => {
    const url = constants.base_url + '/health_center/count'
    let response = await Axios.get(url)

    return response.data
}

const getHealthCenter = async (context, payload) => {
    const url = constants.base_url + '/health_center/all'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    if(response.data.status){
        context.commit('SET_HEALTH_CENTER_OPTIONS', response.data)
    }

    return response.data
}

const detailHealthCenter = async (context, payload) => {
    const url = constants.base_url + '/health_center/all'
    let response = await Axios.get(url, { params: payload})

    return response.data
}

const createHealthCenter = async (context, payload) => {
    const url = constants.base_url + '/health_center/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateHealthCenter = async (context, payload) => {
    const url = constants.base_url + '/health_center/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteHealthCenter = async (context, payload) => {
    const url = constants.base_url + '/health_center/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetHealthCenterForm = async (context) => {
    context.commit('RESET_HEALTH_CENTER_FORM')
}

export {
    countHealthCenter,
    getHealthCenter,
    detailHealthCenter,
    createHealthCenter,
    updateHealthCenter,
    deleteHealthCenter,
    resetHealthCenterForm,
}