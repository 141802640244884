<template>
    <content-component>
        <template v-slot:table_create_button>
            <button class="btn custom-btn-02 w-100" @click="open_form_modal()" :disabled="data.length == 5">
                <i class="fas fa-plus"></i>
                Banner
            </button>
        </template>
        <template v-slot:content_body>
            <div class="row g-3" v-if="data.length">
                <div v-for="(banner, index) in data" :key="index" class="col-12 col-lg-4">
                    <div class="rounded shadow-sm">
                        <div class="custom-image-warpper" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
                            <img :src="banner.image_url" alt="banner-image">
                        </div>
                        <div class="d-block text-center custom-upload-preview-button">
                            <div class="mb-3">{{ banner.title }}</div>
                            <button type="button" class="btn custom-btn-02 py-1 px-2" @click.prevent="delete_data(banner.id)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="w-100 p-3 text-center">No Data.</div>
            </div>
        </template>
        <template v-slot:content_modal>
            <banner-form-modal ref="bannerFormModal" @close="close_modal"/>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import BannerFormModal from './BannerFormModal'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'banner-form-modal': BannerFormModal,
        },
        data() {
            return {
                data: [],
            }
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('master_data/getBanner').then((response) => {
                    this.data = response.data
                })
            },
            open_form_modal(){
                this.$refs.bannerFormModal.open_modal()
            },
            close_modal(value){
                if(value){
                    this.init()
                }
            },
            delete_data(value){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('master_data/deleteBanner', value).then((response) => {
                            if(response.status){
                                parent.init()
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
        }
	}
</script>