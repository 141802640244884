const getDefaultState = () => {
	return {
		user: null,
		sign_in_form: {
			email: null,
			password: null
		},
	}
}

const default_state = getDefaultState()

export { default_state }