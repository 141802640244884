import Dashboard from "@/components/admin/dashboard/DashboardComponent";
import User from "@/components/admin/user/UserListComponent";
import HealthCenter from "@/components/admin/health_center/HealthCenterListComponent";
import HealthCenterForm from "@/components/admin/health_center/HealthCenterFormComponent";
import HealthCenterDetail from "@/components/admin/health_center/HealthCenterDetailComponent";
import PolyclinicQueue from "@/components/admin/queue_history/PolyclinicComponent";
import Article from "@/components/admin/article/ArticleListComponent";
import ArticleForm from "@/components/admin/article/ArticleFormComponent";
import ArticleDetail from "@/components/admin/article/ArticleDetailComponent";
import Province from "@/components/admin/master_data/province/ProvinceListComponent";
import Regency from "@/components/admin/master_data/regency/RegencyListComponent";
import District from "@/components/admin/master_data/district/DistrictListComponent";
import Banner from "@/components/admin/master_data/banner/BannerListComponent";

export default [
	// Dashboard
	{
		path : '/dashboard',
		name : 'dashboard',
		meta : { layout: "admin", parent: 'dashboard', sidebar:'dashboard', breadcrumbs: 'Dashboard', title: 'Dashboard', auth: true, role:['Admin', 'Petugas', 'Penulis'] },
		component : Dashboard
	},
	// User
	{
		path : '/user',
		name : 'user',
		meta : { layout: "admin", parent: 'user', sidebar:'user', breadcrumbs: 'Pengguna', title: 'Data Pengguna', auth: true, role:['Admin'] },
		component : User
	},
	// Health Center
	{
		path : '/health_center',
		name : 'health_center',
		meta : { layout: "admin", parent: 'health_center', sidebar:'health_center', breadcrumbs: 'Data Dasar.Puskesmas', title: 'Data Puskesmas', auth: true, role:['Admin'] },
		component : HealthCenter
	},
	{
		path : '/health_center/create',
		name : 'health_center.create',
		meta : { layout: "admin", parent: 'health_center', sidebar:'health_center', breadcrumbs: 'Data Dasar.Puskesmas.Tambah', title: 'Tambah Data Puskesmas', auth: true, role:['Admin'] },
		component : HealthCenterForm
	},
	{
		path : '/health_center/edit/:id',
		name : 'health_center.edit',
		meta : { layout: "admin", parent: 'health_center', sidebar:'health_center', breadcrumbs: 'Data Dasar.Puskesmas.Ubah', title: 'Ubah Data Puskesmas', auth: true, role:['Admin', 'Petugas'] },
		component : HealthCenterForm
	},
	{
		path : '/health_center/detail/:id',
		name : 'health_center.detail',
		meta : { layout: "admin", parent: 'health_center', sidebar:'health_center', breadcrumbs: 'Data Dasar.Puskesmas.Detail', title: 'Detail Data Puskesmas', auth: true, role:['Admin', 'Petugas'] },
		component : HealthCenterDetail
	},
	// Queue History
	{
		path : '/queue_history/polyclinic',
		name : 'queue_history.polyclinic',
		meta : { layout: "admin", parent: 'queue_history', sidebar:'queue_history', breadcrumbs: 'Riwayat Antrian. Poliklinik', title: 'Data Antrian Poliklinik', auth: true, role:['Admin'] },
		component : PolyclinicQueue
	},
	// Article
	{
		path : '/article',
		name : 'article',
		meta : { layout: "admin", parent: 'article', sidebar:'article', breadcrumbs: 'Artikel', title: 'Data Artikel', auth: true, role:['Admin', 'Penulis'] },
		component : Article
	},
	{
		path : '/article/create',
		name : 'article.create',
		meta : { layout: 'admin', parent: 'article', sidebar:'article', breadcrumbs: 'Artikel.Tambah', title: 'Tambah Data Artikel', auth: true, role:['Admin', 'Penulis'] },
		component : ArticleForm
	},
	{
		path : '/article/edit/:id',
		name : 'article.edit',
		meta : { layout: 'admin', parent: 'article', sidebar:'article', breadcrumbs: 'Artikel.Ubah', title: 'Ubah Data Artikel', auth: true, role:['Admin', 'Penulis'] },
		component : ArticleForm
	},
	{
		path : '/article/detail/:id',
		name : 'article.detail',
		meta : { layout: 'admin', parent: 'article', sidebar:'article', breadcrumbs: 'Artikel.Detail', title: 'Detail Data Artikel', auth: true, role:['Admin', 'Penulis'] },
		component : ArticleDetail
	},
	// Master Data
	// Province
	{
		path : '/master_data/province',
		name : 'master_data.province',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.province', breadcrumbs: 'Data Dasar.Provinsi', title: 'Data Provinsi', auth: true, role:['Admin'] },
		component : Province
	},
	// Regency
	{
		path : '/master_data/regency',
		name : 'master_data.regency',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.regency', breadcrumbs: 'Data Dasar.Kabupaten', title: 'Data Kabupaten', auth: true, role:['Admin'] },
		component : Regency
	},
	// District
	{
		path : '/master_data/district',
		name : 'master_data.district',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.district', breadcrumbs: 'Data Dasar.Kecamatan', title: 'Data Kecamatan', auth: true, role:['Admin'] },
		component : District
	},
	// Banner
	{
		path : '/master_data/banner',
		name : 'master_data.banner',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.banner', breadcrumbs: 'Data Dasar.Banner', title: 'Data Banner', auth: true, role:['Admin'] },
		component : Banner
	},
];