<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">Banner</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-3" :class="validation.firstError('form.title') ? 'custom-form-error' : ''">
                    <label class="form-label">Judul</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Judul" v-model="form.title">
                    <div class="custom-form-error-message">{{ validation.firstError('form.title') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.image') ? 'custom-form-error' : ''">
                    <label>Gambar</label>
                    <file-input
                        :reference="'image'"
                        :multiple="true"
                        :maximum="1"
                        :max_size="2097152"
                        :accept="'image/png, image/jpg, image/jpeg'"
                        :extensions="['png', 'jpg', 'jpeg']"
                        @set_queue="queue_files"
                        :old_file="local_file"
                    />
                    <div class="custom-form-error-message">{{ validation.firstError('form.image') }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal(false)">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit()">Tambah</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import FileInput from '@/components/external/input/FileInput'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'modal-component': ModalComponent,
            'file-input': FileInput,
        },
        data() {
            return {
                modal: false,
                local_file: [],
            }
        },
        computed: {
            ...mapGetters({
                form: 'master_data/getBannerForm',
            }),
        },
        validators: {
            'form.title': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
            'form.image': function(value){
                return Validator.custom(function () {
                    if (!Validator.isEmpty(value)) {
                        for(var i = 0; i < value.length; i++) {
                            if (value[i].error != '') {
                                return 'Invalid file format.'
                            }
                        }
                    }
                    else{
                        return 'Wajib diisi.'
                    }
                })
            },
        },
        methods: {
            open_modal(){
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.validation.reset()
                this.$store.dispatch('master_data/resetBannerForm')
                this.$emit('close', value)
            },
            queue_files(data){
                if(data.files.length > 0){
                    let files = []
                    for (let i = 0; i < data.files.length; ++i) {
                        if(data.files[i].file){
                            files.push({
                                error: data.files[i].error,
                                file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
                            })
                        }
                        else{
                            files.push({
                                error: data.files[i].error,
                                file_status: data.files[i].file_status,
                                file_name: data.files[i].file_name,
                                file_url: data.files[i].file_url
                            })
                        }
                    }
                    this.form[data.ref] = files
                }
                else{
                    this.form[data.ref] = null
                }
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        let formData = new FormData();
                        formData.append('title', parent.form.title)

                        if(parent.form.image){
                            for(let i= 0; i < parent.form.image.length; i++) {
                                if(parent.form.image[i].file){
                                    formData.append('image', parent.form.image[i].file, parent.form.image[i].file.name)
                                }
                                else{
                                    formData.append('image', parent.form.image[i].file_status)
                                }
                            }
                        }

                        parent.$store.dispatch('master_data/createBanner', formData).then((response) => {
                            if(response.status){
                                parent.close_modal(true)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menambah data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
            },
        }
	}
</script>