<template>
    <content-component>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ data.health_center_name }}</td>
                        <td>{{ data.polyclinic_name }}</td>
                        <td>{{ data.patient_id }}</td>
                        <td>{{ data.user_name }}</td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="open_modal(data)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component>
        </template>
        <template v-slot:content_modal>
            <modal-component :modal_status="detail_modal" :modal_size="'modal-xl'">
                <template v-slot:modal_header>
                    <div class="modal-title custom-font-lg custom-font-bold">Detail Antrian</div>
                    <button type="button" class="btn-close" @click="close_modal()"/>
                </template>
                <template v-slot:modal_body>
                    <div class="row" v-if="detail">
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>Puskesmas</label>
                                <p>{{ detail.health_center_name }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>ID Poli</label>
                                <p>{{ detail.polyclinic_name }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>ID Pasien</label>
                                <p>{{ detail.patient_id }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>Nama Pasien</label>
                                <p>{{ detail.name }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>Telepon</label>
                                <p>{{ detail.phone }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>Pengguna</label>
                                <p>{{ detail.user_name }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>Tanggal Daftar</label>
                                <p>{{ datetime_format(detail.registration_date) }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="custom-text-group mb-3">
                                <label>Tanggal Kunjungan</label>
                                <p>{{ datetime_format(detail.visit_date) }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="custom-text-group mb-3">
                                <label>No. Antrian</label>
                                <p>{{ detail.queue_no }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="custom-text-group mb-3">
                                <label>Kode Antrian</label>
                                <p>{{ detail.queue_code }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="custom-text-group mb-3">
                                <label>Status</label>
                                <p>{{ detail.status }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center p-3" v-else>
                        <div class="custom-loading-dot"></div>
                    </div>
                </template>
                <template v-slot:modal_footer>
                    <button type="button" class="btn custom-btn-outline-02" @click="close_modal()">Tutup</button>
                </template>
            </modal-component>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'
    import ModalComponent from '@/components/external/ModalComponent'
    import moment from 'moment'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
            'modal-component': ModalComponent,
        },
        data() {
            return {
                table_header: [
                    { name: 'health_center', title: 'Puskesmas', width: '30%', sort: false },
                    { name: 'polyclinic_id', title: 'ID Poli', width: '15%', sort: false },
                    { name: 'patient_id', title: 'ID Pasien', width: '15%', sort: false },
                    { name: 'user_name', title: 'Pengguna', width: '30%', sort: false },
                    { name: '', title: 'Actions', width: '10%', sort: false, align:'text-center' },
                ],
                table_attributes: {
                    search: null,
                    page: 1,
                    paginate: 30,
                },
                table: null,
                table_data: [],
                detail: null,
                detail_modal: false,
            }
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('queue_history/getPolyclinic', value).then((response) => {
                    this.table = response.data
                    if(value.page == 1){
                        this.table_data = this.table.data
                    }
                    else{
                        this.table_data = [ ...this.table_data, ...this.table.data]
                    }
                    this.table_attributes = value
                })
            },
            datetime_format(value){
                return moment(value).format("DD-MM-YYYY / HH:mm:ss")
            },
            open_modal(value){
                this.detail = value
                this.detail_modal = true
            },
            close_modal(){
                this.detail_modal = false
                this.detail = null
            }
        }
	}
</script>