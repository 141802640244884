<template>
    <content-component>
        <template v-slot:table_create_button>
            <button class="btn custom-btn-02 w-100" @click="open_form_modal()">
                <i class="fas fa-plus"></i>
                Pengguna
            </button>
        </template>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ data.name }}</td>
                        <td>{{ data.email }}</td>
                        <td>{{ data.phone }}</td>
                        <td>
                            <div class="custom-alert text-center w-100" :class="data.role_id == 2 ? 'alert-success' : data.role_id == 3 ? 'alert-warning' : 'alert-primary'" role="alert">{{ data.role_name }}</div>
                        </td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="open_detail_modal(data.id)">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="open_form_modal(data.id)" v-if="data.role_id != 4">
                                    <i class="fas fa-pen"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component>
        </template>
        <template v-slot:content_modal>
            <user-form-modal ref="userFormModal" @close="close_modal"/>
            <user-detail-modal ref="userDetailModal" @close="close_modal"/>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'
    import UserFormModal from './UserFormModal'
    import UserDetailModal from './UserDetailModal'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
            'user-form-modal': UserFormModal,
            'user-detail-modal': UserDetailModal,
        },
        data() {
            return {
                table_header: [
                    { name: 'name', title: 'Nama', width: '25%', sort: true },
                    { name: 'email', title: 'Email', width: '25%', sort: true },
                    { name: 'phone', title: 'Telepon', width: '25%', sort: true },
                    { name: 'role_name', title: 'Tipe', width: '15%', sort: true },
                    { name: '', title: 'Actions', width: '10%', sort: false, align:'text-center' },
                ],
                table_attributes: {
                    sort_by: 'name',
                    sort_type: 'asc',
                    search: null,
                    page: 1,
                    paginate: 30,
                },
                table: null,
                table_data: [],
            }
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('user/tableUser', value).then((response) => {
                    this.table = response.data
                    if(value.page == 1){
                        this.table_data = this.table.data
                    }
                    else{
                        this.table_data = [ ...this.table_data, ...this.table.data]
                    }
                    this.table_attributes = value
                })
            },
            open_form_modal(value){
                this.$refs.userFormModal.open_modal(value)
            },
            open_detail_modal(value){
                this.$refs.userDetailModal.open_modal(value)
            },
            close_modal(value){
                if(value){
                    this.table_attributes.page = 1
                    this.get_table(this.table_attributes)
                }
            }
        }
	}
</script>