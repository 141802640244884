import { createStore } from 'vuex'

//Modules
import auth from './auth';
import user from './user';
import health_center from './health_center';
import queue_history from './queue_history';
import article from './article';
import master_data from './master_data';
import utilities from './utilities';

export default createStore({
	modules: {
		auth,
		user,
		health_center,
		queue_history,
		article,
		master_data,
		utilities,
	}
})