<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">{{ id ? 'Ubah' : 'Tambah' }} Pengguna</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-3" :class="validation.firstError('form.role') ? 'custom-form-error' : ''">
                    <label class="form-label">Tipe</label>
                    <v-select v-model="form.role" :options="role_options" placeholder="Tipe" label="label" :reduce="role_options => role_options.id" class="custom-vselect"/>
                    <div class="custom-form-error-message">{{ validation.firstError('form.role') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.health_center_id') ? 'custom-form-error' : ''" v-if="form.role == 2">
                    <label class="form-label">Puskesmas</label>
                    <v-select v-model="form.health_center_id" :options="health_center_options" placeholder="Puskesmas" label="label" :reduce="health_center_options => health_center_options.id" class="custom-vselect"/>
                    <div class="custom-form-error-message">{{ validation.firstError('form.health_center_id') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.name') ? 'custom-form-error' : ''">
                    <label class="form-label">Nama</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Nama" v-model="form.name">
                    <div class="custom-form-error-message">{{ validation.firstError('form.name') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.email') ? 'custom-form-error' : ''">
                    <label class="form-label">Email</label>
                    <input type="email" class="form-control custom-form-control" placeholder="Email" v-model="form.email">
                    <div class="custom-form-error-message">{{ validation.firstError('form.email') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.phone') ? 'custom-form-error' : ''">
                    <label class="form-label">Telepon</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Telepon" v-model="form.phone">
                    <div class="custom-form-error-message">{{ validation.firstError('form.phone') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.password') ? 'custom-form-error' : ''">
                    <label class="form-label">Password</label>
                    <div class="input-group">
                        <input :type="show_password ? 'text' : 'password'" class="form-control custom-form-control" placeholder="Password" v-model="form.password">
                        <div class="input-group-append">
                            <button type="button" class="btn custom-form-control-append-button" @click="password_toggle()"><i class="fas fa-eye"></i></button>
                        </div>
                    </div>
                    <div class="custom-form-error-message">{{ validation.firstError('form.password') }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal(false)">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit()">{{ id ? 'Simpan' : 'Tambah' }}</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import vSelect from 'vue-select'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'modal-component': ModalComponent,
            'v-select': vSelect,
        },
        data() {
            return {
                modal: false,
                show_password: false,
                id: null,
                role_options: [
                    {
                        id: 2,
                        label: 'Petugas',
                    },
                    {
                        id: 3,
                        label: 'Penulis',
                    },
                ]
            }
        },
        computed: {
            ...mapGetters({
                form: 'user/getUserForm',
                health_center_options: 'health_center/getHealthCenterOptions',
            }),
        },
        validators: {
            'form.role': function(value){
                return Validator.value(value).required('Wajib dipilih.')
            },
            'form.health_center_id': function(value){
                if(this.form.role == 2){
                    return Validator.value(value).required('Wajib dipilih.')
                }
            },
            'form.name': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
            'form.email': function(value){
                return Validator.value(value).required('Wajib diisi.').email('Email salah.')
            },
            'form.phone': function(value){
                return Validator.value(value).integer('Hanya angka.').minLength(10).maxLength(12)
            },
            'form.password': function(value){
                if(!this.id || this.form.password){
                    return Validator.value(value).required('Wajib diisi.').minLength(6).maxLength(16).custom(function (){
                        if(!(/\d/.test(value))){
                            return 'Password harus memiliki 1 angka.'
                        }
                        if(!(/[A-Z]/.test(value))){
                            return 'Password harus memiliki 1 huruf kapital.'
                        }
                    })
                }
            },
        },
        created(){
            this.$store.dispatch('health_center/getHealthCenter')
        },
        methods: {
            open_modal(value){
                if(value){
                    this.$store.dispatch('user/detailUser', value).then((response) => {
                        if(response.status){
                            this.id = response.data.id
                            this.form.role = response.data.role_id
                            this.form.health_center_id = response.data.profile.health_center_id
                            this.form.name = response.data.profile.name
                            this.form.email = response.data.email
                            this.form.phone = response.data.phone
                        }
                    })
                }
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.validation.reset()
                this.$store.dispatch('user/resetUserForm')
                this.$emit('close', value)
            },
            password_toggle(){
                this.show_password = !this.show_password
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        if(parent.id){
                            let data = {
                                id: parent.id,
                                data: parent.form
                            }
                            parent.$store.dispatch('user/updateUser', data).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil mengubah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                        else{
                            parent.$store.dispatch('user/createUser', parent.form).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil menambah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
            },
        }
	}
</script>