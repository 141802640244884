<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">Kategori Artikel</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-4">
                    <button class="btn custom-btn-02 w-100" @click="open_form_modal()">
                        <i class="fas fa-plus"></i>
                        Kategori
                    </button>
                </div>
                <div class="col-12">
                    <template v-if="data.length">
                        <div class="custom-text-group" v-for="(category, index) in data" :key="index">
                            <div class="d-flex align-items-end justify-content-between">
                                <p class="m-0">{{ category.name }}</p>
                                <div>
                                    <button type="button" class="btn custom-btn-unborder-primary py-0" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                        <li><span class="dropdown-item" @click="open_form_modal(category.id, category.name)">Edit</span></li>
                                        <li><span class="dropdown-item" @click="delete_data(category.id,)">Delete</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <div class="w-100 text-center">No Data.</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02 w-100" @click="close_modal(false)">Tutup</button>
        </template>
    </modal-component>
    <modal-component :modal_status="form_modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">{{ id ? 'Ubah' : 'Tambah' }} Kategori Artikel</div>
            <button type="button" class="btn-close" @click="close_form_modal()"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-3" :class="validation.firstError('name') ? 'custom-form-error' : ''">
                    <label class="form-label">Nama Kategori</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Nama Kategori" v-model="name">
                    <div class="custom-form-error-message">{{ validation.firstError('name') }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_form_modal()">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit()">{{ id ? 'Simpan' : 'Tambah' }}</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'modal-component': ModalComponent,
        },
        data() {
            return {
                modal: false,
                form_modal: false,
                data: [],
                id: null,
                name: null,
            }
        },
        validators: {
            'name': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
        },
        methods: {
            init(){
                this.$store.dispatch('article/getArticleCategory').then((response) => {
                    this.data = response.data
                })
            },
            open_modal(){
                this.init()
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.$emit('close', value)
            },
            open_form_modal(id, name){
                if(id && name){
                    this.id = id
                    this.name = name
                }
                this.form_modal = true
            },
            close_form_modal(){
                this.form_modal = false
                this.id = null
                this.name = null
                this.validation.reset()
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        if(parent.id){
                            let data = {
                                id: parent.id,
                                data: {
                                    name: parent.name
                                } 
                            }
                            parent.$store.dispatch('article/updateArticleCategory', data).then((response) => {
                                if(response.status){
                                    parent.init()
                                    parent.close_form_modal()
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil mengubah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                        else{
                            let data = {
                                name: parent.name
                            }
                            parent.$store.dispatch('article/createArticleCategory', data).then((response) => {
                                if(response.status){
                                    parent.init()
                                    parent.close_form_modal()
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil menambah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
            },
            delete_data(value){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('article/deleteArticleCategory', value).then((response) => {
                            if(response.status){
                                parent.init()
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
        }
	}
</script>