import Axios from 'axios'
import { constants } from '@/config/constant'

const getArticleCategory = async (context) => {
    const url = constants.base_url + '/article_category/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_ARTICLE_CATEGORY_OPTIONS', response.data)
    }

    return response.data
}

const createArticleCategory = async (context, payload) => {
    const url = constants.base_url + '/article_category/create'
    let response = await Axios.post(url, payload)

    return response.data
}

const updateArticleCategory = async (context, payload) => {
    const url = constants.base_url + '/article_category/update/' + payload.id
    let response = await Axios.patch(url, payload.data)

    return response.data
}

const deleteArticleCategory = async (context, payload) => {
    const url = constants.base_url + '/article_category/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const getArticle = async (context, payload) => {
    const url = constants.base_url + '/article/all'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    return response.data
}

const detailArticle = async (context, payload) => {
    const url = constants.base_url + '/article/detail/' + payload
    let response = await Axios.get(url)

    return response.data
}

const createArticle = async (context, payload) => {
    const url = constants.base_url + '/article/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateArticle = async (context, payload) => {
    const url = constants.base_url + '/article/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteArticle = async (context, payload) => {
    const url = constants.base_url + '/article/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetArticleForm = async (context) => {
    context.commit('RESET_ARTICLE_FORM')
}

export {
    getArticleCategory,
    createArticleCategory,
    updateArticleCategory,
    deleteArticleCategory,
    getArticle,
    detailArticle,
    createArticle,
    updateArticle,
    deleteArticle,
    resetArticleForm,
}