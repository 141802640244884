import { createRouter, createWebHistory } from 'vue-router'

//Routes
import AuthRoutes from './auth/routes';
import AdminRoutes from './admin/routes';
import ErrorRoutes from './error/routes';

var allRoutes = [];

const routes = allRoutes.concat(AuthRoutes, AdminRoutes, ErrorRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router