<template>
    <div class="text-center">
        <div class="custom-page-error-code">404</div>
        <div class="custom-page-error-status mb-3"><i class="fas fa-exclamation-triangle me-2"></i>Page not found.</div>
        <div class="custom-page-error-message">We could not find the page you were looking for.</div>
    </div>
</template>

<script>
	export default {

	}
</script>