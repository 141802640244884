<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">Detail Pengguna</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row" v-if="detail">
                <div class="col-12">
                    <div class="d-flex justify-content-center mb-3" v-if="detail.role_id == 4">
                        <div class="custom-avatar-lg-warpper" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
                            <img :src="detail.profile.photo" alt="customer-image">
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="custom-text-group mb-3">
                        <label>Nama</label>
                        <p>{{ detail.profile.name }}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="custom-text-group mb-3">
                        <label>Email</label>
                        <p>{{ detail.email }}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="custom-text-group mb-3">
                        <label>Telepon</label>
                        <p>{{ detail.phone }}</p>
                    </div>
                </div>
                <div class="col-6" v-if="detail.role_id == 4">
                    <div class="custom-text-group mb-3">
                        <label>Birth Date</label>
                        <p>{{ date_format(detail.profile.birth_date) }}</p>
                    </div>
                </div>
                <div class="col-6" v-if="detail.role_id == 4">
                    <div class="custom-text-group mb-3">
                        <label>Gender</label>
                        <p style="text-transform: capitalize;">{{ detail.profile.gender }}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-6" v-if="detail.role_id == 4">
                    <div class="custom-text-group mb-3">
                        <label>NIK</label>
                        <p>{{ detail.profile.nik }}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-6" v-if="detail.role_id == 4">
                    <div class="custom-text-group mb-3">
                        <label>No. BPJS</label>
                        <p>{{ detail.profile.no_bpjs}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="custom-text-group mb-3">
                        <label>Tipe</label>
                        <p>{{ detail.role_name }}</p>
                    </div>
                </div>
                <div class="col-12" v-if="detail.role_id == 2 || detail.role_id == 4">
                    <div class="custom-text-group">
                        <label>Puskesmas</label>
                        <p>{{ detail.profile.health_center_name }}</p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center p-3" v-else>
				<div class="custom-loading-dot"></div>
			</div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal(false)">Tutup</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import moment from 'moment'

	export default {
        components: {
            'modal-component': ModalComponent,
        },
        data() {
            return {
                modal: false,
                detail: null,
            }
        },
        methods: {
            open_modal(value){
                this.$store.dispatch('user/detailUser', value).then((response) => {
                    this.detail = response.data
                })
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.detail = null
                this.$emit('close', value)
            },
            date_format(value){
                return moment(value).format("DD-MM-YYYY")
            },
        }
	}
</script>