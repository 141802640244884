import Axios from 'axios'
import { constants } from '@/config/constant'

const getProvince = async (context, payload) => {
    const url = constants.base_url + '/province/all'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    return response.data
}

const getRegency = async (context, payload) => {
    const url = constants.base_url + '/regency/all'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    return response.data
}

const getDistrict = async (context, payload) => {
    const url = constants.base_url + '/district/all'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    return response.data
}

const getProvinceOptions = async (context, payload) => {
    const url = constants.base_url + '/province/all'
    let response = await Axios.get(url, {params: payload})

    if(response.data.status){
        context.commit('SET_PROVINCE_OPTIONS', response.data)
    }

    return response.data
}

const getRegencyOptions = async (context, payload) => {
    const url = constants.base_url + '/regency/all'
    let response = await Axios.get(url, {params: payload})

    if(response.data.status){
        context.commit('SET_REGENCY_OPTIONS', response.data)
    }

    return response.data
}

const getDistrictOptions = async (context, payload) => {
    const url = constants.base_url + '/district/all'
    let response = await Axios.get(url, {params: payload})

    if(response.data.status){
        context.commit('SET_DISTRICT_OPTIONS', response.data)
    }

    return response.data
}

const getBanner = async () => {
    const url = constants.base_url + '/banner/all'
    let response = await Axios.get(url)

    return response.data
}

const createBanner = async (context, payload) => {
    const url = constants.base_url + '/banner/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteBanner = async (context, payload) => {
    const url = constants.base_url + '/banner/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetRegencyOptions = async (context) => {
    context.commit('RESET_REGENCY_OPTIONS')
}

const resetDistrictOptions = async (context) => {
    context.commit('RESET_DISTRICT_OPTIONS')
}

const resetBannerForm = async (context) => {
    context.commit('RESET_BANNER_FORM')
}

export {
    getProvince,
    getRegency,
    getDistrict,
    getProvinceOptions,
    getRegencyOptions,
    getDistrictOptions,
    getBanner,
    createBanner,
    deleteBanner,
    resetRegencyOptions,
    resetDistrictOptions,
    resetBannerForm,
}