<template>
    <content-component>
        <template v-slot:content_body>
            <div class="row" v-if="detail">
                <div class="col-12">
                    <div class="custom-text-wrapper mb-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="pb-1 border-bottom">
                                    <h1 class="display-5">{{ detail.title }}</h1>
                                    <small class="text-muted mb-2">{{ datetime_format(detail.date) }} - {{ detail.user }}</small>
                                </div>
                            </div>
                            <div class="col-12 my-3" v-if="detail.image_url">
                                <div class="custom-image-warpper shadow-sm" v-viewer="{toolbar: false, keyboard: false}">
                                    <img :src="detail.image_url" alt="info-image">
                                </div>
                            </div>
                            <div class="col-12 mb-3 my-lg-4">
                                <p class="lead text-center"><em>{{ detail.excerpt }}</em></p>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <p class="custom-textarea-group">{{ detail.body }}</p>
                                </div>
                            </div>
                            <div class="col-12 mt-4" v-if="detail.url">
                                <div class="text-muted custom-font-sm">Kategori</div>
                                <p class="custom-textarea-group">{{ detail.category_name }}</p>
                            </div>
                            <div class="col-12 mt-4" v-if="detail.url">
                                <div class="text-muted custom-font-sm">Alamat Url Sumber</div>
                                <a href="javascript:void(0)" @click="redirect_url(detail.url)">{{ detail.url }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-12 col-lg-3">
                            <router-link :to="{name: 'article'}" class="btn custom-btn-outline-02 w-100">Kembali</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-component>
</template>

<script>
    import ContentComponent from '@/components/external/ContentComponent'
    import moment from 'moment'

	export default {
        components: {
            'content-component': ContentComponent,
        },
        data() {
            return {
                detail: null,
            }
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('article/detailArticle', this.$route.params.id).then((response) => {
                    if(response.status){
                        this.detail = response.data
                    }
                })
            },
            datetime_format(value){
                return moment(value).format("DD-MM-YYYY / HH:mm")
            },
            redirect_url(value){
                window.open('https://' + value, '_blank')
            }
		}
	}
</script>